const Utils = require('@igp/shared').Utils;

class Action {
  constructor(initiator) {
    this.skipPendingMessage = false;
    this.allowedWithoutAuthentication = false;
    this.customResponseTimeoutInMilliSeconds = null;
    this.emitMessageOnInitiatorInstance = false;
    this.alternativeMessageResponseType = null;

    const Socket = require('../../Socket');
    const initiatorIsSocket = initiator instanceof Socket;
    this.pwjs = initiatorIsSocket ? null : initiator;
    this.socket = initiatorIsSocket ? initiator : this.pwjs.socket;
  }

  sendMessage(data = {}) {
    data = Utils.removeNullKeysFromObject(data);
    return this.socket.sendMessage(
      // always create action class through getActionClassInstance
      // that attaches messageType to created class instance
      {
        type: this.messageType,
        data,
      },
      {
        alternativeMessageResponseType: this.alternativeMessageResponseType,
        skipPendingMessage: this.skipPendingMessage,
        pwjsInitiatorInstance: this.emitMessageOnInitiatorInstance ? null : this.pwjs,
        customResponseTimeoutInMilliSeconds: this.customResponseTimeoutInMilliSeconds,
      },
    );
  }

  request(data) {
    return new Promise((resolve, reject) => {
      this.sendMessage(data).then((message) => {
        this.response(message).then(() => {
          resolve(message);
        })
      }).catch((error) => {
        this.socket.emit('message', error.response);
        reject(error);
      })
    });
  }

  response(message) {
    return new Promise((resolve) => {
      this.socket.emit('message', message);
      resolve();
    });
  }
}

exports.getActionClassInstance = function (name, initiator) {
  const actionClassName = Utils.toUpperCamelCase(name);
  let actionClass = Action;
  try {
    actionClass = class extends require(`./${actionClassName}.js`)(Action) {};
  } catch(e) {}
  const actionClassInstance = new actionClass(initiator);
  actionClassInstance.messageType = name;
  return actionClassInstance;
}

// https://justinfagnani.com/2015/12/21/real-mixins-with-javascript-classes/
