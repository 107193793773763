const store = require('../../StoreData');

module.exports = superclass => class extends superclass {

  response(message) {
    return new Promise((resolve) => {
      if (store.state.initOptions.urlHttp) {
        message.downloadUrl = new URL(`tx-history-csv?token=${message.data.token}`, store.state.initOptions.urlHttp).href;
      }
      super.response(message).then(resolve);
    });
  }

}
