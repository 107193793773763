/* global Headers btoa */
const Logger = require('./Logger');
const store = require('./StoreData');
const Utils = require('@igp/shared').Utils;
const Session = require('./Session');

const getBrowserFetchHeaders = response => {
  const headers = {};
  for (const [header, value] of response.headers.entries()) {
    headers[header] = value;
  }
  return headers;
}

const getNodeFetchHeaders = response => {
  const headers = {};
  const h = response.headers._headers;
  for (const header in h) {
    headers[header] = h[header].join('');
  }
  return headers;
}

const getHeaders = response => {
  return response.headers.entries
    ? getBrowserFetchHeaders(response)
    : getNodeFetchHeaders(response);
}

const formatOutput = (response, body) => {
  const headers = getHeaders(response);
  const returnValue = {
    body,
    headers,
    response,
    status: response.status,
    statusText: response.statusText
  };

  return response.ok
    ? Promise.resolve(returnValue)
    : Promise.reject(returnValue);
}

async function parseResponse (response, type) {
  let body;
  if (type === 'formData') {
    body = await response.text();
    body = Object.fromEntries(new URLSearchParams(body));
  } else {
    body = await response[type]();
  }
  return formatOutput(response, body);
}

const setHeaders = ({ headers = {}, customHeaders = {}, method = 'get', auth } = {}) => {
  if (typeof Headers === 'undefined') {
    require('cross-fetch/polyfill');
  }

  const h = new Headers(headers);
  for (const [key, value] of Object.entries(customHeaders)) {
    h.set(key, value);
  }

  if (method === 'options') return h;

  // Use default content type as 'application/json' for POST, PUT, PATCH, DELETE
  if (!h.get('content-type') && method !== 'get') {
    h.set('content-type', 'application/json; charset=utf-8');
  }

  if (h.has('content-type') && h.get('content-type').includes('multipart/form-data')) {
    h.delete('content-type');
  }

  if (auth) {
    if (Utils.isObject(auth)) {
      const { username, password } = auth
      if (!username) {
        Logger.error('Username required for basic authentication.');
        throw new Error('Username required for basic authentication.')
      }
      if (!password) {
        Logger.error('Password required for basic authentication.');
        throw new Error('Password required for basic authentication.')
      }

      const btoa = typeof window !== 'undefined' && 'btoa' in window
      ? window.btoa
      : require('btoa');

      h.set('Authorization', 'Basic ' + btoa(`${username}:${password}`));
    } else {
      h.set('Authorization', `Bearer ${auth}`);
    }
  } else {
    h.set('Authorization', `SessionId ${Session.get()}`);
  }

  return h;
}

const queryStringify = (params = {}) => {
  return new URLSearchParams(params).toString();
}

const createURL = opts => {
  let { url, queries } = opts;
  if (!Utils.isValidHttpUrl(url)) {
    url = url.replace(/^\/+|\/+$/g, '');
    url = `${store.state.initOptions.urlHttp}${url}`;
  }
  return queries
    ? `${url}?${queryStringify(queries)}`
    : url;
}

const formatBody = (opts = {}) => {
  if (opts.method === 'get') {
    return;
  }

  const contentType = opts.headers.get('content-type');
  if (!contentType) {
    return opts.body;
  }

  if (contentType.includes('x-www-form-urlencoded')) {
    return queryStringify(opts.body);
  }

  if (contentType.includes('json')) {
    return JSON.stringify(opts.body);
  }

  return opts.body
}

const createRequestOptions = (options = {}) => {
  const opts = { ...options };

  opts.url = createURL(opts);
  opts.method = opts.method || 'get';
  opts.headers = setHeaders(opts);
  opts.body = formatBody(opts);
  opts.mode = 'cors';

  // Removes options that are not native to Fetch
  delete opts.auth
  return opts
}

const handleResponse = (response, options) => {
  if (options && 'customResponseParser' in options) {
    return response;
  }

  const type = response.headers.get('content-type');

  if (!type) return formatOutput(response, null);
  if (type.includes('json')) return parseResponse(response, 'json');
  if (type.includes('text')) return parseResponse(response, 'text');
  if (type.includes('image')) return parseResponse(response, 'blob');
  if (type.includes('x-www-form-urlencoded')) return parseResponse(response, 'formData');

  Logger.error(`pwjs does not support content-type ${type}.`);
  throw new Error(`pwjs does not support content-type ${type}.`);
}

const handleError = error => {
  Logger.error(error);
  return error.message === 'Failed to fetch'
    ? Promise.reject({ error })
    : Promise.reject(error);
}

module.exports = Object.freeze({
  createRequestOptions,
  handleResponse,
  handleError,
});
