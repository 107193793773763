const requests = require('./Requests');
const store = require('./../../StoreData');
const Utils = require('@igp/shared').Utils;
const Logger = require('./../../Logger');
const {
  handleResponse,
  handleError,
  createRequestOptions
} = require('./../../Fetch');

class Request {
  constructor() {
    this.customHeaders = {};
  }

  request(url, options) {
    options.customHeaders = this.customHeaders;
    const requestOptions = createRequestOptions({ ...{ url }, ...options});
    Logger.logHTTPRequest(requestOptions);
    return fetch(requestOptions.url, requestOptions);
  }

  response(responseData, options) {
    Logger.logHTTPResponse(responseData);
    return Promise.resolve(handleResponse(responseData, options))
      .catch(handleError);
  }
}

exports.getRequestClassInstance = function (url) {
  const isValidUrl = Utils.isValidHttpUrl(url);
  const origin = isValidUrl
    ? `${new URL(url).origin}/`
    : store.state.initOptions.urlHttp;
  const pathname = isValidUrl
    ? new URL(url).pathname
    : url;
  const requestedUrLPath = `${origin}${pathname.replace(/^\/+|\/+$/g, '')}`;
  const request = requests.find((request) => {
    const { origin, pathname} = new URL(request.pathName, store.state.initOptions.urlHttp);
    const savedUrlPath = `${origin}${pathname}`;
    return requestedUrLPath === savedUrlPath;
  });
  const RequestClass = request ? class extends request.requestClass(Request) {} : Request;
  Logger.debug(`Using request class ${request ? request.pathName : 'Request'}`);
  return new RequestClass();
}

// https://justinfagnani.com/2015/12/21/real-mixins-with-javascript-classes/
