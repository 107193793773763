const { Utils, EventEmitter } = require('@igp/shared');
const Logger = require('./Logger');
const packageName = require('../../package.json').name;
const packageVersion = require('../../package.json').version;

let instance = null;
const initialBalanceState = {
  total: null,
  version: -1,
  bonus: null,
  real: null,
}
const state = {
  initOptions: {},
  package: {
    name: packageName,
    version: packageVersion,
  },
  socketConnected: false,
  loggedIn: false,
  languageCode: null,
  player: {},
  productBlocks: [],
  balance: initialBalanceState,
  logLevel: 'error',
}

class Store extends EventEmitter(class Base { }) {
  constructor() {
    if (instance) {
      return instance;
    }

    function createProxy(target) {
      return new Proxy(target, {
        get: (target, prop) => {
          let val = target[prop];
          try {
            val = Utils.isObject(val) ? createProxy.call(this, val) : val;
          } catch (err) { }
          return val;
        },

        set: (obj, prop, value) => {
          const oldStateString = JSON.stringify(this.state);
          const oldState = JSON.parse(oldStateString);
          Reflect.set(obj, prop, value);
          const newStateString = JSON.stringify(this.state);
          const newState = JSON.parse(newStateString);
          if (oldStateString !== newStateString) {
            this.emit('stateChanged', `${prop}`, newState, oldState);
            Logger.debug(`state changed, prop: ${prop}, value: ${value}`);
            Logger.debugTable(newState);
          }
          return true;
        },

        deleteProperty() {
          return false;
        },
      })
    }

    super();
    this.logLevels = require('./Logger').logLevels;
    this.state = createProxy.call(this, state);
  }

  setStateBalance(balance) {
    if (
      !Utils.isObject(balance) &&
      Utils.isNullOrUndefined(balance.balance) && 
      Utils.isNullOrUndefined(balance.balanceVersion) && 
      Utils.isNullOrUndefined(balance.realBalance) && 
      Utils.isNullOrUndefined(balance.bonusBalance) &&
      this.state.balance.version > balance.balanceVersion
    ) {
      return;
    }

    balance = {
      version: balance.balanceVersion,
      total: balance.balance,
      real: balance.realBalance,
      bonus: balance.bonusBalance,
    }
    this.state.balance = balance;
  }

  setStateInitOption(key, value) {
    this.state.initOptions[key] = value;
  }

  setStateSocketConnected() {
    this.state.socketConnected = true;
  }

  setStateSocketDisconnected() {
    this.state.socketConnected = false;
  }

  setStateLogin({ sessionId } = {}) {
    const Session = require('./Session');
    Session.set(sessionId);
    this.state.loggedIn = true;
  }

  setStateLogout() {
    this.state.player = {};
    this.state.loggedIn = false;
    this.state.balance = initialBalanceState;
  }

  setStateLogLevel(logLevel) {
    logLevel = logLevel?.toString().toLowerCase();
    if (this.logLevels.includes(logLevel)) {
      Logger.log(`logLevel set to: ${logLevel}`);
      this.state.logLevel = logLevel;
    }
    return this.state.logLevel;
  }
}

module.exports = new Store();
