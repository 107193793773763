const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  response(message) {
    return new Promise((resolve) => {
      store.state.player = message.data;
      super.response(message).then(resolve);
    });
  }

}
