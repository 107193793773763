const store = require('../../StoreData');
const Session = require('../../Session');

module.exports = superclass => class extends superclass {

  response(message) {
    return new Promise((resolve) => {
      Session.delete();
      store.setStateLogout();
      super.response(message).then(resolve);
    });
  }

}
