const store = require('../../StoreData');

module.exports = superclass => class extends superclass {

  response(message) {
    return new Promise((resolve) => {
      if (message.data) {
        store.state.productBlocks = message.data;
      }
      super.response(message).then(resolve);
    });
  }

}
