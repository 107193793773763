const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  request(data) {
    data.languageCode = data.languageCode || store.state.languageCode;
    return super.request(data);
  }

}
