const store = require('../../StoreData');

module.exports = superclass => class extends superclass {

  response(message) {
    return new Promise((resolve) => {
      store.state.languageCode = message.data.languageCode;
      super.response(message).then(resolve);
    });
  }

}
