const Errors = require('./lib/Errors');
const { ConnectionError } = require('./lib/Errors');
const root = require('./lib/Root');
const Socket = require('./lib/Socket');
const SocketEventCodes = require('./lib/SocketEventCodes');
const store = require('./lib/StoreData');
const { Utils, EventEmitter } = require('@igp/shared');
const { getActionClassInstance } = require('./lib/mixins/actions/Action');
const { getRequestClassInstance } = require('./lib/mixins/requests/Request');
const setApiOptions = require('./lib/DefaultOptions').setApiOptions;
const Logger = require('./lib/Logger');
const Session = require('./lib/Session');
const pwjsUtils = require('./lib/mixins/utils');

let masterInstance = null;
const slaveInstances = [];

function createProxy(target) {
  return new Proxy(target, {
    get: (target, prop) => {
      if (prop === '_value_') {
        return JSON.parse(JSON.stringify(target));
      }
      let val = target[prop];
      try {
        val = Utils.isObject(val) || Utils.isArray(val) ? createProxy(val) : val;
      } catch (err) { }
      return val;
    },

    set() {
      return true;
    },

    deleteProperty() {
      return false;
    },
  });
}

function socketOnOpenHandler(pwjsInstance) {
  return () => {
    pwjsInstance.emit('socket:opened');

    if (pwjsInstance._isSlaveInstance()) {
      return;
    }

    store.setStateLogout();
    pwjsInstance.send('auth').then(() => {
      store.setStateSocketConnected();
      pwjsInstance.emit('connected');
      slaveInstances.forEach((si) => si.emit('connected'));
    }).catch((error) => {
      Logger.error(error.payload || error);
      let socketCloseErrorCode = SocketEventCodes.AUTHENTICATION_ERROR;
      if (error?.payload === 'RATE_LIMIT' || error?.payload?.code === 'RATE_LIMIT') {
        socketCloseErrorCode = SocketEventCodes.AUTHENTICATION_RATE_LIMIT;
      }
      pwjsInstance.socket.close(socketCloseErrorCode);
      if (error?.payload?.code === 'SESSION_INVALID') {
        Session.delete();
      }
    });
  };
}

function socketOnCloseHandler(pwjsInstance) {
  return (event) => {
    store.setStateSocketDisconnected();
    pwjsInstance.emit('socket:closed');
    pwjsInstance.emit('disconnected', event);
  };
}

function socketOnMessageHandler(pwjsInstance) {
  return (message = {}) => {
    if (message.__pwjsInitiatorInstance__ === pwjsInstance) {
      return;
    }
    message = clearMessage(message);
    // only emit specific type event in case it was successful
    if (!('error' in message)) {
      pwjsInstance.emit(`message:${message.type}`, message.data);
    }
    // only general message handler wil emit error responses as well
    pwjsInstance.emit('message', message);
  };
}

function storeOnStateChanged(pwjsInstance) {
  function emitStateChangedOnSubObject(prop, newState, oldState, eventName) {
    const newValue = newState?.[prop];
    const oldValue = oldState?.[prop];

    if (!(Utils.isObject(newValue) && Utils.isObject(oldValue))) {
      return;
    }

    const combinedNewOldValueObject = { ...newValue, ...oldValue };

    for (const key in combinedNewOldValueObject) {
      const newEventName = `${eventName}:${key}`;
      if (Utils.isObject(combinedNewOldValueObject[key])) {
        emitStateChangedOnSubObject(key, newValue, oldValue, newEventName);
      }
      const newStatePropString = JSON.stringify(Utils.isObject(newValue) ? newValue[key] : newValue);
      const oldStatePropString = JSON.stringify(Utils.isObject(oldValue) ? oldValue[key] : oldValue);
      if (newStatePropString !== oldStatePropString) {
        pwjsInstance.emit(`stateChanged:${newEventName}`, newValue[key], oldValue[key]);
      }
    }
  };

  return (prop, newState, oldState) => {
    emitStateChangedOnSubObject(prop, newState, oldState, prop);
    pwjsInstance.emit(`stateChanged:${prop}`, newState[prop], oldState[prop]);
    pwjsInstance.emit('stateChanged', newState, oldState);
  };
}

function attachSocketEventListeners(pwjsInstance) {
  pwjsInstance.socket.on('open', socketOnOpenHandler(pwjsInstance));
  pwjsInstance.socket.on('close', socketOnCloseHandler(pwjsInstance));
  pwjsInstance.socket.on('message', socketOnMessageHandler(pwjsInstance));
}

function attachStoreEventListeners(pwjsInstance) {
  store.on('stateChanged', storeOnStateChanged(pwjsInstance));
}

function createSlaveInstance(pwjsInstance, newPwjsInstance) {
  slaveInstances.push(newPwjsInstance);
  newPwjsInstance.state = pwjsInstance.state;
  newPwjsInstance.socket = pwjsInstance.socket;
  newPwjsInstance.plugins = pwjsInstance.plugins;
  newPwjsInstance.utils = pwjsInstance.utils;
  attachSocketEventListeners(newPwjsInstance);
  attachStoreEventListeners(newPwjsInstance);
  return newPwjsInstance;
}

function clearMessage(message) {
  message && delete message.__pwjsInitiatorInstance__;
  try { message = JSON.parse(JSON.stringify(message)) } catch (err) { };
  return message;
}

function loadPlugins(plugins, pwjsInstance) {
  plugins?.forEach((pluginDefinition) => {
    if (
      pluginDefinition.plugin &&
      Utils.isNonEmptyString(pluginDefinition.plugin.pluginName) &&
      Utils.isFunction(pluginDefinition.plugin.load)
    ) {
      const p = pluginDefinition.plugin.load({ options: pluginDefinition.options, pwjs: new Pwjs(pwjsInstance) });
      if (p) {
        pwjsInstance.plugins[pluginDefinition.plugin.pluginName] = p;
      }
      Logger.debug(`Plugin ${pluginDefinition.plugin.pluginName} loaded.`);
    }
  });
}

class Pwjs extends EventEmitter(class Base { }) {
  constructor(initOptions) {
    if (initOptions instanceof Pwjs) {
      super();
      const slaveInstance = createSlaveInstance(initOptions, this);
      Logger.debug('slave instance created');
      return slaveInstance;
    }

    if (masterInstance) {
      Logger.debug('master instance already exists, same instance returned');
      return masterInstance;
    }

    super();

    !Utils.isObject(initOptions) && (initOptions = {});
    setApiOptions(initOptions);

    this.state = createProxy(store.state);
    this.socket = new Socket(this.state.initOptions.url, this);
    this.plugins = {};
    this.utils = pwjsUtils;
    attachSocketEventListeners(this);
    attachStoreEventListeners(this);
    loadPlugins(initOptions.plugins, this);

    masterInstance = this;
    // create a slave instance and attach it to global scope
    root._pwjs = new Pwjs(this);

    Utils.setLocalStorage('deviceId', Utils.getLocalStorage('deviceId') || Utils.generateUUID());
    Logger.debug('master instance created');
  }

  connect(options) {
    this.socket.connect(options);
    Logger.debug('connect function called');
  }

  disconnect() {
    this.socket.close(SocketEventCodes.INTENTIONAL_DISCONNECT);
    Logger.debug('disconnect function called');
  }

  isLoggedIn() {
    return store.state.loggedIn;
  }

  send(actionName, ...args) {
    return new Promise((resolve, reject) => {
      const action = getActionClassInstance(actionName, this);

      Utils.promiseRetry(() =>
        store.state.socketConnected || action.allowedWithoutAuthentication
          ? Promise.resolve()
          : Promise.reject()
      )
        .then(() => action.request(...args))
        .then((message) => resolve(clearMessage(message)))
        .catch((err) => reject(err || new ConnectionError(ConnectionError.definitions.NO_WS_CONNECTION)));
    });
  }

  getState() {
    return JSON.parse(JSON.stringify(store.state));
  }

  getDeviceId() {
    return Pwjs.getDeviceId();
  }

  setLogLevel(logLevel) {
    return store.setStateLogLevel(logLevel);
  }

  _getSessionId() {
    return Pwjs._getSessionId();
  }

  _isSlaveInstance() {
    return slaveInstances.includes(this);
  }
}

const methods = ['get', 'post', 'put', 'patch', 'delete'];
for (const method of methods) {
  Pwjs.prototype[method] = function (url, options) {
    options = { ...{ method }, ...options };
    const requestClassInstance = getRequestClassInstance(url);
    return requestClassInstance.request(url, options)
      .then((response) => requestClassInstance.response(response, options));
  }
}

Pwjs.Errors = Errors;

Pwjs._getSessionId = () => Session.get();

Pwjs.getDeviceId = () => Utils.getLocalStorage('deviceId');

module.exports = Object.freeze(Pwjs);
