const { Utils } = require('@igp/shared');
const store = require('../lib/StoreData');

module.exports = class Session {
  static set(sessionId) {
    Utils.setLocalStorage('sessionId', sessionId);
    let sessionIdObject = Utils.getLocalStorage('_sessionId') || {};
    sessionIdObject[store.state.initOptions.cage] = sessionId;
    Utils.setLocalStorage('_sessionId', sessionIdObject);
  }

  static get() {
    let sessionIdObject = Utils.getLocalStorage('_sessionId') || {};
    let sessionId = sessionIdObject[store.state.initOptions.cage];
    return sessionId || Utils.getLocalStorage('sessionId');
  }

  static delete() {
    Utils.clearLocalStorage('sessionId');
    let sessionIdObject = Utils.getLocalStorage('_sessionId') || {};
    delete sessionIdObject[store.state.initOptions.cage];
    Utils.setLocalStorage('_sessionId', sessionIdObject);
  }
}
