const camelCase = require('lodash.camelcase');
const localStorage = require('./lib/LocalStorage');

const LOCAL_STORAGE_PREFIX = 'pwjs_ls';

const isBoolean = function(x) {
  return typeof x == "boolean";
}

const isObject = function(obj) {
  return obj && typeof obj === 'object' && !Array.isArray(obj);
}

const isArray = function(obj) {
  return Array.isArray(obj);
}

const isFunction = function(obj) {
  return typeof obj === 'function';
}

const isUndefined = function(x) {
  return typeof x === 'undefined';
}

const isNullOrUndefined = function(x) {
  return typeof x === 'undefined' || x === null;
}

const isInteger = function(x) {
  return Number.isInteger(x);
}

const isString = function(x) {
  return typeof x === 'string' || x instanceof String;
}

const isNonEmptyString = function(x) {
  return isString(x) && x;
}

const toCamelCase = function(s) {
  return camelCase(s);
}

const toUpperCamelCase = function(s) {
  return capitalizeFirstChar(toCamelCase(s));
}

const capitalizeFirstChar = function(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const isValidHttpUrl = function(s) {
  let url;
  
  try {
    url = new URL(s);
  } catch (e) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

const isLocalStorageSupported = function() {
  if (isNullOrUndefined(localStorage)) {
    return false;
  }
  const key = generateUUID();
    try {
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
}

const setLocalStorage = function(key, value, ttl) {
  if (!isLocalStorageSupported()) {
    return false;
  }

  let currentLs = getLocalStorage() || {};
  currentLs[key] = {
    value,
    expires: isNaN(ttl) ? null : Date.now() + Number(ttl),
  }
  localStorage.setItem(LOCAL_STORAGE_PREFIX, JSON.stringify(currentLs));
}

const getLocalStorage = function(key) {
  if (!isLocalStorageSupported()) {
    return null;
  }

  let ls = localStorage.getItem(LOCAL_STORAGE_PREFIX);

  try {
    ls = JSON.parse(ls);
  } catch (e) {
    return null;
  }

  if (!ls) {
    return null;
  }

  if (isUndefined(key)) {
    return ls;
  }

  if (!ls.hasOwnProperty(key)) {
    return null;
  }

  if (ls[key].expires !== null && Date.now() > ls[key].expires) {
    clearLocalStorage(key);
    return null;
  }

  return ls[key].value;
}

const clearLocalStorage = function(key) {
  if (!isLocalStorageSupported()) {
    return false;
  }

  if (isUndefined(key)) {
    localStorage.removeItem(LOCAL_STORAGE_PREFIX);
  } else {
    const currentLs = getLocalStorage();
    if (currentLs) {
      delete currentLs[key];
      localStorage.setItem(LOCAL_STORAGE_PREFIX, JSON.stringify(currentLs));
    }
  }
}

const promiseRetry = function(fn, { retries = 5, interval = 1000 } = {}) {
  return new Promise((resolve, reject) =>
    fn()
      .then(resolve)
      .catch((error) => {
        if (retries === 0) {
          return reject(error);
        }
        setTimeout(() => {
          promiseRetry(fn, { retries: retries - 1, interval })
            .then(resolve, reject);
        }, interval);
      }),
  );
}

// for browser usage, function is overwritten in shims/Browser.js
const generateUUID = function() {
  const crypto = require('crypto');
  return crypto?.randomUUID ? crypto.randomUUID() : null;
}

const removeNullKeysFromObject = function (obj) {
  if (!isObject(obj)) {
    return obj;
  }

  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce(
      (acc, [k, v]) => ({ ...acc, [k]: removeNullKeysFromObject(v) }),
      {}
    );
}

const loadScript = function(url) {
    const script = document.createElement('script');
    const prior = document.getElementsByTagName('script')[0];
    script.async = 1;

    return new Promise((resolve, reject) => {
      script.onload = script.onreadystatechange = (_, isAbort) => {
        if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
          script.onload = script.onreadystatechange = null;

          return !isAbort ? resolve(script) : reject(new Error('Unable to load script.'));
        }
      };

      script.onerror = () => reject(new Error('Unable to load script.'));

      script.src = url;
      prior.parentNode.insertBefore(script, prior);
    });
  }

module.exports = {
  isBoolean,
  isObject,
  isArray,
  isFunction,
  isUndefined,
  isNullOrUndefined,
  isInteger,
  isString,
  isNonEmptyString,
  isValidHttpUrl,
  toCamelCase,
  toUpperCamelCase,
  capitalizeFirstChar,
  setLocalStorage,
  getLocalStorage,
  clearLocalStorage,
  promiseRetry,
  generateUUID,
  removeNullKeysFromObject,
  loadScript,
}
