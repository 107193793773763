const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  constructor(initiator) {
    super(initiator);
    this.alternativeMessageResponseType = 'login';
  }

  response(message) {
    return new Promise((resolve) => {
      store.setStateLogin({ sessionId: message.data.sessionId });
      super.response(message).then(resolve);
    });
  }

}
