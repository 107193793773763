const store = require('../../../lib/StoreData');

module.exports = superclass => class extends superclass {

  response(message) {
    return new Promise((resolve) => {
      store.setStateLogin({ sessionId: message.data.sessionId });
      super.response(message).then(resolve);
    });
  }

}
