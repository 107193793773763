// const markdownToHtml = require('./mdToHtml.js');
module.exports = Object.freeze({
  markdownToHtml: (s) => {
    return new Promise((resolve, reject) => {
      import('marked')
        .then((marked) => {
          resolve(marked.parse(s));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});
